import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import Chip from '@material-ui/core/Chip';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import Truck from '@material-ui/icons/LocalShipping';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import StopTimeStatus from 'components/StopTimeStatus';

// styles
import styles from './styles';
import fake from './fake.json';

const serviceLevel = {
  guaranteed: 'https://storage.googleapis.com/archer-app/guaranteed.png',
  premium: 'https://storage.googleapis.com/archer-app/premium.png',
  economy: 'https://storage.googleapis.com/archer-app/economy.png',
}

const status = {
  Ready: 'https://storage.googleapis.com/archer-app/status/processing.png',
  Pending: 'https://storage.googleapis.com/archer-app/pending.png',
  Processing: 'https://storage.googleapis.com/archer-app/status/processing.png',
  Pickup: 'https://storage.googleapis.com/archer-app/status/pickup.png',
  'En Route': 'https://storage.googleapis.com/archer-app/status/enRoute.png',
  Dropped: 'https://storage.googleapis.com/archer-app/status/dropped.png',
  Delivered: 'https://storage.googleapis.com/archer-app/status/delivered.png',
  Arrived: 'https://storage.googleapis.com/archer-app/status/delivered.png',
  Invoiced: 'https://storage.googleapis.com/archer-app/status/delivered.png',
}

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getLoadById: PropTypes.func,
    getLoadStops: PropTypes.func,
    getMapUpdates: PropTypes.func,
    getShipConDispatches: PropTypes.func,
    client: PropTypes.any,
  };

  state = {
    loadingLoad: false,
    loadingStops: false,
    ref: '',
  };

  constructor(...args) {
    super(...args);
    this.container = React.createRef();
  }

  componentDidMount() {
    this.resize();
  }

  init() {
    const { client } = this.props;
    this.setState({ load: undefined, stops: undefined, error: '', ref: '' }, this.resize);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  resize() {
    const { client } = this.props;
    if (this.container && this.container.current.clientHeight){
      console.log('resize', this.container.current.clientHeight);
    }
    if (client && client.invoke && this.container && this.container.current) {
      console.log(this.container.current.clientHeight);
      client.invoke('resize', {
        width: 360,
        height: this.container.current.clientHeight > 500 ? 500 : this.container.current.clientHeight,
        });
    }
  }

  async quicktrack() {
    const { getLoadById, getLoadStops, client, getMapUpdates, getShipConDispatches } = this.props;
    const { ref } = this.state;
    const refToSearch = ref;
    if (refToSearch === '') return;
    this.init();
    this.setState({ loadingLoad: true });
    const resp = await getLoadById(refToSearch);

    if (resp.success) {
      const load = resp.payload;
      this.setState({ load, loadingLoad: false, loadingStops: true }, this.resize);
      const resp1 = await getLoadStops(load.id);
      let stops = resp1.payload;
      this.setState({ stops, loadingStops: false }, this.resize);
    } else {
      this.setState({ loadingLoad: false, error: 'Load not Found' });
    }
  }

  orderTrip(trip) {
    const tmpTripObj = {};
    let structuredTrip = [];
    let toEnd = [];
    for (const key in trip) {
      if (trip.hasOwnProperty(key)) {
        const tripItem = trip[key];
        if (tripItem.trip_order === 0) {
          toEnd.push(tripItem);
        } else {
          if (tmpTripObj[tripItem.stop_date] === undefined) {
            tmpTripObj[tripItem.stop_date] = [tripItem]
          } else {
            tmpTripObj[tripItem.stop_date].push(tripItem);
          }
        }
      }
    }

    console.log(tmpTripObj);

    for (const key in tmpTripObj) {
      if (tmpTripObj.hasOwnProperty(key)) {
        const element = tmpTripObj[key];
        element.sort((a, b) => a.trip_order - b.trip_order);
        structuredTrip = structuredTrip.concat(element);
      }
    }

    structuredTrip = structuredTrip.concat(toEnd);

    return structuredTrip;

  }

  render() {
    const { classes, client } = this.props;
    const { 
      ref, 
      loadingLoad, 
      load, 
      stops, 
      loadingStops, 
      error 
    } = this.state;

    const structuredTrip = this.orderTrip(stops);

    console.log(structuredTrip);

    return (
        <div 
          className={classes.container} 
          ref={this.container}
        >
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.root} elevation={1}>
                <InputBase 
                  className={classes.input} 
                  onKeyPress={(e) => { if (e.key === 'Enter') this.quicktrack(); }}
                  placeholder="Enter Ref or WA#" 
                  value={ref}
                  fullWidth
                  onChange={this.handleChange('ref')}
                />
                <IconButton 
                  className={classes.iconButton} 
                  aria-label="Search"
                  onClick={this.quicktrack.bind(this)}
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} />
                <IconButton 
                  className={classes.iconButton} 
                  aria-label="Clear"
                  onClick={this.init.bind(this)}
                >
                  <Close />
                </IconButton>
              </Paper>
            </Grid>
            {
              loadingLoad
                ? (
                  <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10 }}>
                    <CircularProgress color="primary" />
                  </Grid>
                ) : []
            }
            {
              load
                ? (
                  <Grow in>
                    <Grid item style={{ marginTop: 10 }} xs={12}>
                      <div style={{ padding: 20 }}>
                        <Grid container justify="space-between">
                          <Grid item>
                            <Typography variant="h6">
                              Load #{load.id}
                            </Typography>
                            <Typography 
                              color="textSecondary" 
                              variant="caption"
                              style={{ marginTop: -5 }}
                            >
                              ({load.reference_number})
                            </Typography>
                          </Grid>
                          {
                            serviceLevel[load.service_level]
                              ? (
                                <Grid item>
                                  <img 
                                    src={serviceLevel[load.service_level]}
                                    alt="serviceLevel" 
                                    style={{ height: 30 }}
                                  />
                                </Grid>
                              ) : []
                          }
                          <Grid item xs={12}>
                            <img
                              src={status[load.status]}
                              alt="status" 
                              style={{ height: 22, marginTop: 5 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <div 
                              style={{ 
                                marginTop: 15, 
                                padding: 5, 
                                background: '#eceff1',
                                border: 'solid 1px #cfd8dc',
                                borderRadius: 4,
                              }}
                            >
                              <Typography>
                                <b>Last Location</b>
                              </Typography>
                              <Grid container>
                                <Grid item>
                                  <Typography>
                                    {load.last_location} 
                                  </Typography>
                                  <Typography>
                                    {load.last_loc_city} {load.last_loc_state} 
                                  </Typography>
                                  <Typography>
                                    {moment.parseZone(load.last_loc_transaction_time_local).format('YYYY/MM/DD HH:mm')} (Local time)
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item style={{ marginTop: 15 }} xs={12}>
                          <Grid container justify="space-between"  alignItems="center">
                              <Grid item>
                                <Typography><b>Customer:</b></Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{load.customer_name} lbs</Typography>
                              </Grid>
                            </Grid>
                            <Grid container justify="space-between" alignItems="center">
                              <Grid item>
                                <Typography><b>Type:</b></Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{load.load_type}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container justify="space-between" alignItems="center">
                              <Grid item>
                                <Typography><b>Pallets:</b></Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{load.pallets} Pallets</Typography>
                              </Grid>
                            </Grid>
                            <Grid container justify="space-between"  alignItems="center">
                              <Grid item>
                                <Typography><b>Weight:</b></Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{load.weight} lbs</Typography>
                              </Grid>
                            </Grid>
                            {
                              load.reefer_cont
                                ? (
                                  <Grid container justify="space-between"  alignItems="center">
                                    <Grid item>
                                      <Typography><b>Reefer Temp</b></Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>{load.reefer_temp} ℉</Typography>
                                    </Grid>
                                  </Grid>
                                ) : []
                            }
                            {
                              loadingStops
                                ? (
                                  <Grid container>
                                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10 }}>
                                      <CircularProgress color="primary" size={20}/> <Typography>Loading Stops...</Typography>
                                    </Grid>
                                  </Grid>
                                ) : []
                            }
                            {
                              structuredTrip && structuredTrip.map((s, i) => {
                                const relatedStop = s.load_id === load.id;
                                const completed = s.eta_info && s.eta_info.departed_at_utc !== null;
                                const onSite = (s.eta_info && s.eta_info.arrived_at_utc !== null) && (s.eta_info && s.eta_info.departed_at_utc === null);
                                const localEta =  s.eta_info && s.eta_info.local_eta;
                                let color = '#9e9e9e';
                                if (completed) color = '#4caf50';
                                if (onSite) color = '#2196f3';
                              
                                return (
                                  <div style={{ position: 'relative' }}>
                                    {
                                      onSite
                                        ? (
                                          <Truck 
                                            style={{
                                              position: 'absolute',
                                              width: 20,
                                              height: 20,
                                              left: -8,
                                              color,
                                            }} 
                                          />
                                        ) : []
                                    }
                                    <Grid 
                                      key={s.id}
                                      container
                                      alignItems="stretch"
                                      style={{ 
                                        marginTop: Number(i) === 0 ? 10 : 0,
                                        marginLeft: 6,
                                        background: relatedStop ? '#eceff1' : undefined,
                                        borderRadius: 4,
                                      }}
                                    >
                                      <Grid 
                                        item 
                                        style={{ 
                                          borderLeft: Number(i) !== stops.length - 1 
                                            ? `solid 2px ${color}` 
                                            : 'solid 2px white',
                                          marginLeft: 20,
                                        }}
                                      >
                                        <div 
                                          style={{ 
                                            color: 'white', 
                                            background: color,
                                            height: relatedStop ? 20 : 10,
                                            width: relatedStop ? 20 : 10,
                                            textAlign: 'center',
                                            borderRadius: 10,
                                            marginLeft: relatedStop? -11 : -6,
                                          }}
                                        >
                                          {
                                            relatedStop
                                                ? s.stop_number
                                                : ''
                                          }
                                        </div>
                                      </Grid>
                                      <Grid 
                                        item 
                                        style={{ 
                                          padding: '0px 0px 10px 10px', 
                                          flexGrow: 1,
                                          maxWidth: 'calc(100% - 40px)',
                                        }}>
                                        <Grid container spacing={16} justify="space-between">
                                          <Grid item xs={12}>
                                            {
                                              s.load_id === load.id
                                                ? (
                                                  <div>
                                                    <Typography>
                                                      {
                                                        s.ship_cons_type === 'consignee' 
                                                          ? (
                                                            <ArrowDownward style={{ height: 20}} />
                                                          ) : []
                                                      }
                                                      {
                                                        s.ship_cons_type === 'shipper' 
                                                          ? (
                                                            <ArrowUpward style={{ height: 20}} />
                                                          ) : []
                                                      }
                                                        <b>{s.name}</b>
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {moment.parseZone(s.stop_date).format('YYYY/MM/DD')}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {s.line1}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {s.zip} {s.city} {s.state}
                                                    </Typography>
                                                    {
                                                      s.current_trailer
                                                        ? (
                                                          <Typography variant="caption">
                                                            Trailer: <b>{s.current_trailer}</b>
                                                          </Typography>
                                                        ) : []
                                                    }
                                                    {
                                                      s.driver_name
                                                        ? (
                                                          <Typography variant="caption">
                                                            Driver: <b>{s.driver_name}</b>
                                                          </Typography>
                                                        ) : []
                                                    }
                                                    <StopTimeStatus 
                                                      stop={s}
                                                    />
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <Typography variant="caption" color="textSecondary">
                                                      {
                                                        s.ship_cons_type === 'consignee' 
                                                          ? (
                                                            <ArrowDownward style={{ height: 15}} />
                                                          ) : []
                                                      }
                                                      {
                                                        s.ship_cons_type === 'shipper' 
                                                          ? (
                                                            <ArrowUpward style={{ height: 15 }} />
                                                          ) : []
                                                      }
                                                      {s.name}
                                                    </Typography>
                                                    <Typography variant="caption" color="textSecondary">
                                                      {moment.parseZone(s.stop_date).format('YYYY/MM/DD')}
                                                    </Typography>
                                                    {
                                                      s.current_trailer
                                                        ? (
                                                          <Typography variant="caption">
                                                            Trailer: <b>{s.current_trailer}</b>
                                                          </Typography>
                                                        ) : []
                                                    }
                                                    <Typography variant="caption" color="textSecondary">
                                                      {s.city} {s.state}
                                                    </Typography>
                                                    <StopTimeStatus 
                                                      stop={s}
                                                    />
                                                  </div>
                                                )
                                            }
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                );
                              })
                            }
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grow>
                ) : []
            }
            {
              error
                ? (
                  <Grid item xs={12} style={{ marginTop: 15, textAlign: 'center'}}>
                    <Chip
                      label={error}
                    />
                  </Grid>
                )
                : []
            }
          </Grid>
        </div>
    );
  }
}

export default withStyles(styles)(App);
