import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// custom
import Signin from 'components/Signin';
import App from 'components/App';

// actions
import * as Actions from 'actions';

// styles
import styles from './styles';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  getLoadById: (...args) => dispatch(Actions.getLoadById(...args)),
  getLoadStops: (...args) => dispatch(Actions.getLoadStops(...args)),
  getMapUpdates: (...args) => dispatch(Actions.getMapUpdates(...args)),
  getShipConDispatches: (...args) => dispatch(Actions.getShipConDispatches(...args)),
  signin: (...args) => dispatch(Actions.signin(...args)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getCurrentUser: PropTypes.func,
    getLoadById: PropTypes.func,
    getLoadStops: PropTypes.func,
    getMapUpdates: PropTypes.func,
    getShipConDispatches: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { getCurrentUser } = this.props;
    let client;
    try {
      client = window.ZAFClient.init();
    } catch (error) {
      console.log('ZAFClient not defined');
    }

    this.state = {
      loading: true,
      client,
    };

    this.routine();
  }

  async routine() {
    const { getCurrentUser, client } = this.props;
    const resp = await getCurrentUser();
    this.setState({ loading: false });
  }

  render() {
    const { 
      classes,
      getLoadById,
      getLoadStops,
      getShipConDispatches,
      getMapUpdates,
      user,
      signin,
    } = this.props;
    const { loading, client } = this.state;

    return (
        <div 
          className={classes.container}
        >
          <Grid container justify="center" style={{ width: '100%'}}>
            {
              loading
                ? (
                  <Grid item>
                    <CircularProgress color="primary" />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {
                      user.uuid
                        ? (
                          <App 
                            getLoadById={getLoadById}
                            getLoadStops={getLoadStops}
                            getMapUpdates={getMapUpdates}
                            getShipConDispatches={getShipConDispatches}
                            client={client}
                          />
                        ) : (
                          <Signin 
                            signin={signin}
                            client={client}
                          />
                        )
                    }
                  </Grid>
                )
            }
          </Grid>
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root));
