import {
    GET,
  } from 'constants/methods';
  
  
  const service = 'v1';
  const loads = `/${service}/loads`;
  const shipConDispatches = `/${service}/ship_con_dispatches`;
  
  export function getLoadById(id) {
    const url = `${loads}/${id}`;
    return {
      service,
      method: GET,
      url,
    };
  }

  export function getLoadStops(id) {
    const url = `/v1/ship_cons/map_ship_cons.json?load_id=${id}`;
    return {
      service,
      method: GET,
      url,
    };
  }
  
  export function getMapUpdates(id) {
    const url = `${loads}/${id}/map_updates`;
    return {
      service,
      method: GET,
      url,
    };
  }

  export function getShipConDispatches(id) {
    const url = `${shipConDispatches}/map_ship_con_dispatches/?load_id=${id}`;
    return {
      service,
      method: GET,
      url,
    };
  }
