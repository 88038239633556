import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

// styles
import styles from './styles';

class StopTimeStatus extends Component {
  static propTypes = {
    classes: PropTypes.object,
    stop: PropTypes.object,
  };

  state = {
    hover: false,
  };

  fixDate(time, date) {
    if (time && date) {
      return `${date.substring(0,11)}${time.substring(11,20)}`
    }
    return undefined;
  }

  render() {
    const { classes, stop } = this.props;
    const completed = stop.eta_info && stop.eta_info.departed_at_utc !== null;
    const onSite = (stop.eta_info && stop.eta_info.arrived_at_utc !== null) && (stop.eta_info && stop.eta_info.departed_at_utc === null);
    const localEta =  stop.eta_info && stop.eta_info.local_eta;
    const dateTo = this.fixDate(stop.time_from, stop.stop_date);
    const late = moment.duration(moment(stop.eta_info.local_eta).diff(moment(dateTo))).asMinutes();
    const { hover } = this.state;

    let prefix;
    let background;
    let color;
    let key;
    let keyHover;
    let duration = false;
    if (completed) {
      prefix = 'Departed';
      background = '#e0e0e0';
      color = 'rgba(0, 0, 0, 0.54)';
      key = 'departed_at_utc';
      keyHover = 'departed_at';
      duration = moment.duration(moment(stop.eta_info.departed_at_utc).diff(moment(stop.eta_info.arrived_at_utc))).asMinutes();
    }
    if (localEta) {
      prefix = 'ETA';
      background = '#e91e63';
      color = 'white';
      key = 'utc_eta';
      keyHover = 'utc_eta';
    }
    if (onSite) {
      prefix = 'Arrived';
      background = '#2196f3';
      color = 'white';
      key = 'arrived_at_utc';
      keyHover = 'arrived_at';
    }
    if (!onSite && !completed && late) {
      prefix = 'ETA';
      background = '#ff9800';
      color = 'white';
    }

    if (prefix === undefined) { return <div></div> }

    return (
      <div
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <span
          onMouseEnter={() => this.setState({ hover: true })}
          style={{
            background,
            color,
            padding: '2px 4px 2px 4px',
            borderRadius: 10,
            fontSize: 12,
            cursor: 'pointer'
          }}
        >
          {prefix} {moment.parseZone(stop.eta_info && stop.eta_info[key]).fromNow()} {!onSite && !completed && late ? `(${Math.round(late * 100) / 100} min late)` : []}
        </span>
          <Grow in>
            <div
              style={{
                paddingLeft: 4,
                paddingTop: 4,
                borderLeft: 'solid 3px #f5f5f5'
              }}
            >
              {
                duration && hover
                  ? (
                    <Typography color="textSecondary" variant="caption">
                      Stayed {Math.round(duration * 100) / 100} min on site.
                    </Typography>
                  ) : []
              }
              {
                (completed || onSite) && hover
                  ? (
                    <Typography color="textSecondary" variant="caption">
                      Arrived At {moment.parseZone(stop.eta_info && stop.eta_info.arrived_at).format('YYYY/MM/DD HH:mm')} (Local Time)
                    </Typography>
                  ) : []
              }
              {
                completed && hover
                  ? (
                    <Typography color="textSecondary" variant="caption">
                      Departed At {moment.parseZone(stop.eta_info && stop.eta_info.departed_at).format('YYYY/MM/DD HH:mm')} (Local Time)
                    </Typography>
                  ) : []
              }
              {
                !completed && hover && (stop.eta_info.local_eta !== null)
                  ? (
                    <Typography color="textSecondary" variant="caption">
                      ETA {moment.parseZone(stop.eta_info && stop.eta_info.local_eta).format('YYYY/MM/DD HH:mm')} (Local Time)
                    </Typography>
                  ) : []
              }
              {
                !completed && hover && (stop.eta_info.local_eta !== null)
                  ? (
                    <Typography color="textSecondary" variant="caption">
                      Appointment {moment.parseZone(dateTo).format('YYYY/MM/DD HH:mm')} (Local Time)
                    </Typography>
                  ) : []
              }
            </div>
          </Grow>
        </div>
    );
  }
}

export default withStyles(styles)(StopTimeStatus);
