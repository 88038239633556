import { networkAction } from 'helpers/network/networkAction';

import {
  GET_LOAD_BY_ID,
  GET_LOAD_STOPS,
  GET_MAP_UPDATES,
  GET_SHIP_CON_DISPATCHES,
} from 'constants/loads';

import * as Api from 'api';

export const getLoadById = id => async dispatch => networkAction(
  dispatch, GET_LOAD_BY_ID, Api.getLoadById, [id],
);

export const getLoadStops= id => async dispatch => networkAction(
  dispatch, GET_LOAD_STOPS, Api.getLoadStops, [id],
);

export const getMapUpdates = id => async dispatch => networkAction(
  dispatch, GET_MAP_UPDATES, Api.getMapUpdates, [id],
);

export const getShipConDispatches = id => async dispatch => networkAction(
  dispatch, GET_SHIP_CON_DISPATCHES, Api.getShipConDispatches, [id],
);
